import React, {useEffect, useState} from 'react';
import { Card, Respects } from './styles';
import Reports from '../../views/Reports';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import Slide from '../../components/Slide';
import cookie from '../../services/cookie/index';

export default function Home() {

  const [firstName, setFirstName] = useState("");

  useEffect(() => {
    getFirstName()
  })

  const getFirstName = async () => {
    let name = await cookie().get("firstName");
    setFirstName(name)
  }

  return (
    <div>

      <Slide title={<><FontAwesomeIcon icon={faHome} /> Home</>}/>

      <Respects>Olá <b>{firstName}</b>!</Respects>

      <Card>
        <Reports />
      </Card>
      
    </div>
  );
}
