import ApiConnection from './connection';

const login = async (userData) => ApiConnection.post(`users/auth`, userData)
.then(response => {
  return {output:response.data, status: response.status};
}).catch((e) => {
  return {status: e.response.status, message: e.response.data.message}
});

const getAllByPage = async (token) => ApiConnection.get(`users`, { headers: { Authorization: `Bearer ${token}` }})
.then(response => {
  return {output:response.data, status: response.status};
}).catch((e) => {
  return {status: e.response.status}
});

const getByID = async (token, id) => ApiConnection.get(`users/${id}`, { headers: { Authorization: `Bearer ${token}` }})
.then(response => {
  return {output:response.data, status: response.status};
}).catch((e) => {
  return {status: e.response.status}
});

const create = async (token, userData) => ApiConnection.post(`users`, userData, { headers: { Authorization: `Bearer ${token}` }})
.then(response => {
  return {output:response.data, status: response.status};
}).catch((e) => {
  return {status: e.response.status}
});

const update = async (token, userData, userID) => ApiConnection.put(`users/${userID}`, userData, { headers: { Authorization: `Bearer ${token}` }})
.then(response => {
  return {output:response.data, status: response.status};
}).catch((e) => {
  return {status: e.response.status}
});

const ban = async (token, userID) => ApiConnection.put(`users/${userID}/ban`, {}, { headers: { Authorization: `Bearer ${token}` }})
.then(response => {
  return {output:response.data, status: response.status};
}).catch((e) => {
  return {status: e.response.status}
});

const active = async (token, userID) => ApiConnection.put(`users/${userID}/active`, {}, { headers: { Authorization: `Bearer ${token}` }})
.then(response => {
  return {output:response.data, status: response.status};
}).catch((e) => {
  return {status: e.response.status}
});


export default function user() {
  return {
    login,
    getByID,
    getAllByPage,
    create,
    ban,
    active,
    update
  }
}