import ApiConnection from './connection';

const getAll = async (token, dateStart, dateEnd, patient) => ApiConnection.get(`reports/${dateStart}/${dateEnd}`, { headers: { Authorization: `Bearer ${token}` }})
.then(response => {
  return {output:response.data, status: response.status};
}).catch((e) => {
  return {status: e.response.status}
});

const download = async (token, numberReport, typeReport) => ApiConnection.get(`reports/download/${numberReport}/${typeReport}`, { headers: { Authorization: `Bearer ${token}` }})
.then(response => {
  return {output:response.data, status: response.status};
}).catch((e) => {
  return {status: e.response.status}
});

export default function report() {
  return {
    getAll,
    download
  }
}