import React, {useState} from 'react';
import { Table, Button, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faSearch } from '@fortawesome/free-solid-svg-icons'
import { formatDate } from '../../utils/functions';
import api from '../../services/client/api';
import cookie from '../../services/cookie/index';
import {useHistory} from 'react-router-dom';
import {deleteCookies} from '../../utils/functions';

export default function ListReports({reports}) {

  const [dowloading, setDownloading] = useState({numberReport:null});
  let history = useHistory();

  const download = async (numberReport, typeReport) => {
    setDownloading({numberReport:numberReport})

    const token = await cookie().get("token")
    const response = await api().report().download(token, numberReport, typeReport);
    
    if (response.status === 401) 
    {
      await deleteCookies();
      return history.push({ pathname:"/login"});
    } 
    
    if (response.status === 200) 
    {
      window.open(response.output)
      setDownloading({numberReport:null})
      
      return
    } 
    setDownloading({numberReport:null})
  }

  if (reports.length < 1) {
    return (<div></div>)
  }

  return (
    <div style={{marginTop:"50px"}}>
      <h5>
        <FontAwesomeIcon icon={faSearch} /> {reports.length} resultados encontrados
      </h5>
      <Table striped bordered responsive style={{marginTop:"50px"}}>
        <thead>
          <tr>
            <th>#Exame</th>
            <th>Funções</th>
            <th>Paciente</th>
            <th>Data de Nascimento</th>
            <th>Instituição de origem</th>
            <th>Data da solicitação</th>

          </tr>
        </thead>
        <tbody>

          {reports.map((report) => (
            <tr>
              <th scope="row">{report.id_exam}</th>
              <td>
                {report.can_download?
                  <Button disabled={dowloading.numberReport === report.id_exam} color="primary" onClick={() => download(report.id_exam, report.type)}>
                  { (dowloading.numberReport === report.id_exam) ?
                    <><Spinner color="primary"/> Downloading</>
                  :
                    <><FontAwesomeIcon icon={faDownload} /> Download</>
                  }
                  </Button>
                :
                  "Documento não quitado."
                }
              </td>
              <td>{report.patient}</td>
              <td>{formatDate(report.birthdate)}</td>
              <td>{report.institution}</td>
              <td>{formatDate(report.request_date)}</td>

            </tr>
          ))}
          
        </tbody>
      </Table>
    </div>

  );
}