import React from 'react';
import { Container, LeftDiv, RightDiv } from './styles';
import LoginView from '../../views/Login/index.jsx';
import bgLoginMedico from '../../assets/bg-login-medico.png';
import logo from '../../assets/logo.png';
import {deleteCookies} from '../../utils/functions';

function Login() {
  
  deleteCookies();
  
  return (
      <Container>

        <LeftDiv background={bgLoginMedico}>
          <h1>
          “<b>Excelência</b> em <b>saúde,</b><br/> 
          porque sua <b>vida vale<br/>
          muito</b> para <b>nós!</b>”
          </h1>
        </LeftDiv>

        <RightDiv>
          <h1>Área do Paciente</h1>
          <LoginView/>
          
          <img src={logo} alt="Logo CEDAP"/>

        </RightDiv>

      </Container>
  );
}

export default Login;
