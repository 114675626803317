import report from './report';
import user from './user';

const api = () => {

  return {
    report,
    user
  }
}

export default api;