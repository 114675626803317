import styled from 'styled-components';

export const Button = styled.button`
  padding: 10px 50px;
  border-radius: 50px;
  background: ${(props) => props.active ? 'linear-gradient(268.2deg,#006EB0 -5.28%,#00AFEF 100%,#007DC3 100%)' : '#fff'};
  color: ${(props) => props.active ? '#FFF' : '#333'};
  border: 1px solid #bbb;
  margin: 0 20px 70px 20px;
  box-shadow: 3px 3px 2px rgba(0,0,0,0.09);
  font-size: ${(props) => props.active ? 23 : 18}px;
  font-weight: ${(props) => props.active ? 600 : 400};

  :focus {
    outline: 0;
  }

  @media (max-width: 990px) {
    font-size: 16px;
    margin: 0 5px 40px 5px;
    padding: 10px 20px;
  }
`;

export const Wrapper = styled.div`
  display: block;
  text-align: center;
`;

export const Card = styled.div`
  padding: 70px;
  width: calc(100% - 140px);
  margin: 70px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 3px 3px 5px rgba(0,0,0,0.1);
  border: 1px solid #ddd;

  h1 {
    color: #0C2B6E;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    margin-bottom: 30px;
  }

  @media (max-width: 990px) {
    width: 100%;
    margin: 0;
    padding: 30px;
    border-radius: 0;
    box-shadow: none;
    border: none;
  }
`;

export const Respects = styled.h3`
  display: block;
  width: calc(100% - 140px);
  margin:auto;
  margin-top:60px;
  
  @media (max-width: 990px) {
    width: 100%;
    margin: 0;
    padding: 30px;
  }
`;